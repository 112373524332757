import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "color"
    }}>{`Color`}</h2>
    <p><em parentName="p">{`Note: The UI Shell does not currently use the Carbon theme tokens; theming options for the shell will be available in the future. All color used in the UI Shell is from the `}<a parentName="em" {...{
          "href": "https://www.ibm.com/design/language/elements/color#specifications"
        }}>{`IBM Design Langauge palette`}</a>{`.`}</em></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 100`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 80`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-trigger`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__nav::before`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 80`}</td>
        </tr>
      </tbody>
    </table>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "17.857142857142858%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAAsSAAALEgHS3X78AAAAbElEQVQY062POwoAIQxEBa+gKP5QK7G182IeQkxh5203sd7tNvCagXlDGPv7Ukqn1jqdc6C1hpwztNYghADGGLDWglLqE+/9QjYyhBCcxRhPKWVicAUk7L0DDl0Z5VSksTdweCEbGVJK/vvHDwdgIno1gu+5AAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "ui shell header",
        "title": "ui shell header",
        "src": "/static/fbfaf744a7c43145ecabd804d1a1c01f/fb070/header-style-1.png",
        "srcSet": ["/static/fbfaf744a7c43145ecabd804d1a1c01f/d6747/header-style-1.png 288w", "/static/fbfaf744a7c43145ecabd804d1a1c01f/09548/header-style-1.png 576w", "/static/fbfaf744a7c43145ecabd804d1a1c01f/fb070/header-style-1.png 1152w", "/static/fbfaf744a7c43145ecabd804d1a1c01f/fb104/header-style-1.png 1728w", "/static/fbfaf744a7c43145ecabd804d1a1c01f/8fefe/header-style-1.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "menu-trigger"
    }}>{`Menu-trigger`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-trigger`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-trigger:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 100-hover`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-trigger:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`White`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-trigger:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 80`}</td>
        </tr>
      </tbody>
    </table>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36.684782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsSAAALEgHS3X78AAABUUlEQVQoz2Nob29noBb4//8/hJGTk8NaW1vLMX/+fOZ58+axNDc3s9y7d48pISGBPT4+nn3p0qXMc+bMYWlra2O5c+cOU0VFBUd9fT37ggULmGfOnMnS2dnJcv36dSZkk5lIcUlhYSF+BSYmJhGmpqYnlZSUdmlra+/h4+M7CHTxGiCepqWldUBVVXWPpqbmHgEBgUMTJkxYWlJSMsfY2PigsrLyHjU1td1iYmKHgb6YB3QYG9hAc3Nzfxsbm01AjUuACpcJCwuvqq6unl5XV9eqq6u7GmjYMkNDw2UiIiKre3t7JwK93G1tbb0aaNgyfX39pZKSkmsWLlzYDTSQlcHPz4+RgdrAzc2NMTIyksnIyIgpKCiISUhIiGn27NlMYWFhjBwcHExA1zMBLWYCupBp+fLlTBMnTmSKiopiMjMzY/Ly8mICupDp0KFDTKBYBgAX4XcLEwLcawAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Menu trigger interactive states",
            "title": "Menu trigger interactive states",
            "src": "/static/d2bb66a6bce0c988d7ec9a49ff9c2047/fb070/header-style-5.png",
            "srcSet": ["/static/d2bb66a6bce0c988d7ec9a49ff9c2047/d6747/header-style-5.png 288w", "/static/d2bb66a6bce0c988d7ec9a49ff9c2047/09548/header-style-5.png 576w", "/static/d2bb66a6bce0c988d7ec9a49ff9c2047/fb070/header-style-5.png 1152w", "/static/d2bb66a6bce0c988d7ec9a49ff9c2047/c3e47/header-style-5.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Menu-trigger interactive states</Caption>
      </Column>
    </Row>
    <h3 {...{
      "id": "menu-item"
    }}>{`Menu-item`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-item`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 30`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-item`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`svg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 30`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-item:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 100-hover`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-item:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-item:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`svg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-item:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`White`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-item:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 80`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-item:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-item:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`svg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 10`}</td>
        </tr>
      </tbody>
    </table>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "82.88043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsSAAALEgHS3X78AAACd0lEQVQ4y7WTy27aUBCGoaiLQCOS2piAwZRgcw2pTamQcCt2XVTKpSAuSgClVVZFLFqrUislXlV9n1ZeRc42b8E2Id4nJATi/p6GqMqimyZHGs14fM5/Zj6PXa4/yw3z/G1zc3Oeu7l/WbFY9FxfX7tJzbZtt+s+VyQSKSWTyR8rKyt7iPclSdJLpZKeSCR0QRB05PRYLKbzPK8vLy9THAqFyJDbn5+f32s2m9+n0+kLElxaWtqF4Pnq6uoJNpzikFWpVKxMJmPh2QqHwxaELQhY8Xic4mAwaHEcZ+HsqdfrPdnc3Dy/vLx8T4LlcvnRDYv/WkB3J7ivhVbegdcRWjxAe2YulzPRvpnNZs1oNGo6uZkHP4rRqom2KYeWD8DwCIWtzQTX0un0oSzLP8HMwMcxMAbkIWDg0K0HX4ohZoChAWHD5/P9Wl9fPxyNRm9dD7K2traeNBqNCG5hUDrT6/UY5JharUZ+e3ubabVaTLvdZur1Ou3pdDpk3W6X9vT7fXYwGDyetbybSqXO8vn8MdoaguGwWq0OgWDoPDuGUSGPsaEYLZPh7BAMj3HRGeZwhwTBQgGXr+D4CUw0eA3VaYVCQcMhzcmBLcUQ0zDoWiAQIHNy+E0/b2xsfLu4uJAfhiHGQ0B7b2T5+SspmSyLibiaS4uqJCZUoFBFUbz1qJ5ijBkZflMVVZbB9/VkMgnOGH6BqI3Zm0QivB3io7aUztuhMG/HBMHGe1u48RgbioGJDDhsMJzio9rj8fjjjCG3uLj40u9fkFlmQRFyFeWZ+kHhRUVhnvoVjgsqLMsqDmuGYShGVWS4RMEcKmBevLq6Yl14ea/4fgOanuRtF8GMjwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Menu item interactive states",
            "title": "Menu item interactive states",
            "src": "/static/f75c12203737ddd1d5a6b3bfd53d0fad/fb070/header-style-6.png",
            "srcSet": ["/static/f75c12203737ddd1d5a6b3bfd53d0fad/d6747/header-style-6.png 288w", "/static/f75c12203737ddd1d5a6b3bfd53d0fad/09548/header-style-6.png 576w", "/static/f75c12203737ddd1d5a6b3bfd53d0fad/fb070/header-style-6.png 1152w", "/static/f75c12203737ddd1d5a6b3bfd53d0fad/c3e47/header-style-6.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Sub-menu interactive states</Caption>
      </Column>
    </Row>
    <h3 {...{
      "id": "sub-menu"
    }}>{`Sub-menu`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__submenu`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 90`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__submenu`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 30`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__submenu:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 90-hover`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__submenu:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__submenu:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`White`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__submenu:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 70`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__submenu:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 10`}</td>
        </tr>
      </tbody>
    </table>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "40.714285714285715%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsSAAALEgHS3X78AAABpElEQVQoz7VQvU7CYBRtNDaIgykJP4Hw//+XBlrAtkKVQiBK2IyJg7IwOhgwPgDpI+jgaBwcfQp3Ex/A6ODAYhwcCuTzXGh4A7/kpv3OPd8591yO+4+TTqczsiy39nAymYzidrsPhsNhu9/v75dKpXq1WlWSyaTi8/mM0Wh0aBiGXqlUtHK5rEajUQXVNU2zshb0eDwnEGKFQsEKh8NMEIT3wWBwq+v6HcxYNpudBYNBwl/H47EJoed8Ps/QmwUCgbnX632ZTCbXa0GAfC6X+ywWi0Ri8Xj8gXCIiMDmqAUZYsorwjHRGczJaBGLxRjuLcIdDscmZxOciPxRq9WYJElUj4R3u10JU1qNRsPSNI3V6/XlFM1m87zX67FOp2OBw9rt9hHhuG8sBRHTKYriF4nhyxDniXCsVCYT7JDBkCHqDeHY3wWMmKqqDBzqHRMO3mpC7GAbkd4g9I24M0x8bxuJwKdUiDaPRCKXdqJTYL+pVGoK7Ae8ls1fTcjzPIfGbiKREPx+vwu1Yz/cxN4EKuzTFQqFHPZDHvtzQVDAP9UW4ehzf/cUfLhrZYnPAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Sub-menu interactive states",
        "title": "Sub-menu interactive states",
        "src": "/static/f5a5719ae8f2987f65c4b336d16a62af/fb070/header-style-7.png",
        "srcSet": ["/static/f5a5719ae8f2987f65c4b336d16a62af/d6747/header-style-7.png 288w", "/static/f5a5719ae8f2987f65c4b336d16a62af/09548/header-style-7.png 576w", "/static/f5a5719ae8f2987f65c4b336d16a62af/fb070/header-style-7.png 1152w", "/static/f5a5719ae8f2987f65c4b336d16a62af/fb104/header-style-7.png 1728w", "/static/f5a5719ae8f2987f65c4b336d16a62af/8fefe/header-style-7.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption"></Caption>
    <h3 {...{
      "id": "action"
    }}>{`Action`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__action`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 30`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__action:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 100-hover`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__action:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__action:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`White`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__action:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 80`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__action:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 10`}</td>
        </tr>
      </tbody>
    </table>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "82.88043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsSAAALEgHS3X78AAABsElEQVQ4y7WUO2vCUBiGU4ODYrQoXkK8xEuoijrUQZCALjpJqcYKnfwFLlKc+wcKBaVDbX+FXcRBEDtUt9jL5tyldK3SevoewbWcthp4CIecPHzfey4cIYTv9/s8x3H/wmq18mazmdv+gwq74/G463K5bkRR7FI8Hg8Tfr+fcq0oym0mk7lMpVImKlxA+G6323VMegQzt9v9I5s5kK2JRCIv+Xz+vl6vC1RomM/n/FbblmXZOJlMTFROF+mP0H/XGT6PRqOZw+GgLc9YWt5A2/V6vXo8Hn9Kp9MPzWbTRYVDVHcHYRsL0qFAygSEHZ/P104mk1fZbPai1WrZdrJt5MFgcJDL5RKqqiq/BdtFKRQKSqlUCmFsoEIyHA4/sW0+0MYS2TCDlpfIcJFIJFblcvm1WCzaqPBc1/UzCCuYpEFaxZsJSZKqyFCLxWIntVrtqNFoGHeS4XGv16ui/NNgMKgFAoEKK5hfCYVCFZwULRqNlsLhsHGdIW4bIgjCF7YMQSvMoAgC8QoHg4A3i8WyT4XqdDo9xEDCRwmZSAiaCUglXCqS0+n0Yg1EXBJ7W4/wG88OKNud2WVmAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Header action interactive states",
            "title": "Header action interactive states",
            "src": "/static/2a7b4127b3fa333503e5fa42daa3c6cf/fb070/header-style-8.png",
            "srcSet": ["/static/2a7b4127b3fa333503e5fa42daa3c6cf/d6747/header-style-8.png 288w", "/static/2a7b4127b3fa333503e5fa42daa3c6cf/09548/header-style-8.png 576w", "/static/2a7b4127b3fa333503e5fa42daa3c6cf/fb070/header-style-8.png 1152w", "/static/2a7b4127b3fa333503e5fa42daa3c6cf/c3e47/header-style-8.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Header action interactive states</Caption>
      </Column>
    </Row>
    <h2 {...{
      "id": "typography"
    }}>{`Typography`}</h2>
    <p>{`Menu labels and text should be set in sentence case.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-size (px/rem)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-weight`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$body-short-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__name--prefix`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SemiBold / 600`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$heading-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-item`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$body-short-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "structure"
    }}>{`Structure`}</h2>
    <p>{`The header should span the full width of the browser window. The header can either stay sticky to the top of the browser or scroll away.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px/rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48 / 3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-trigger`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height, width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48 / 3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32 / 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-07`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__nav`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-item`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left, padding right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__menu-arrow`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8 / 0.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__submenu`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left, padding right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__action`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height, width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48 / 3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
      </tbody>
    </table>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "38.57142857142858%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsSAAALEgHS3X78AAABP0lEQVQoz2NgoDYwrq8VNLG3l3Ca0C9t5uYpquntI2WYli6joWcgpikqIaJn7SimGhIqLczFJSIuKSkqLi6OgUXFxMT0JRU5/8f/Z2BwKyrK1dTVvRXS33/K3NntjJGV7Wn7iKgzWmpap1XFpE4Z6ZmeMXJxPyMkwH9KXELiFNAAdHwCaN4VZQmZcLALHVJSWUUZGDi8pkzmUQ8J5zXXNuSwDwjh1FZQ5ZTgF+IMUTThCbX25OHk5+GUlJDkBBoAxkBDOMWAtIKMLCePpCgngxg3M8Lf//9THHT/daBmuOhapBlYWB1RU1fbKCEpuRlo82agCyBYTBzOhokj0xISEptl5eQ2OiroHMhQsAoAGxin46BuY2LlrKSsZAX0kg1QoQ1QA2EMVCcqLmatKafk4KWg75ykYCFB9RTDwMjAAAAu2lPWSGuRjAAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "ui shell header spec",
        "title": "ui shell header spec",
        "src": "/static/fe2b407220eada8ffb2e1c8522b81e0c/fb070/header-style-2.png",
        "srcSet": ["/static/fe2b407220eada8ffb2e1c8522b81e0c/d6747/header-style-2.png 288w", "/static/fe2b407220eada8ffb2e1c8522b81e0c/09548/header-style-2.png 576w", "/static/fe2b407220eada8ffb2e1c8522b81e0c/fb070/header-style-2.png 1152w", "/static/fe2b407220eada8ffb2e1c8522b81e0c/fb104/header-style-2.png 1728w", "/static/fe2b407220eada8ffb2e1c8522b81e0c/8fefe/header-style-2.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">
  Structure and spacing measurements for UI shell header | px | rem
    </Caption>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "38.57142857142858%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsSAAALEgHS3X78AAABVElEQVQoz2NgoCIQFxdnZFBUVIzQ1tZeLCMjM01KSmqGrKzsDGlJyRlKWloz7AICJtgEBEwxCAycIsDFNVFCQmImUNMMMTGxGSAaiqcD8TwgNgWbKi0tbaOgoJAnKSmZBtSQDjQ0Q1RAIFXdyjLXNjT0n6mV1X8DK8v/WlaWL2S1tVKFhQTTgZozgIaCaBBOA+JsIFbB6/z///8zeCcm37M0Mv5nqm/w3ycrezdRXga6iFFOTo4Z6EJmoAuZVQ0NWUCSE5++53eJjX1orK//X1db+79DTMxBoCVsQCkWcV0tZklxcWagK5mBhsAwI1ZbIiZMANNr/v/ndIyOfmOgp/dfW1Pzv11MzEmYmpL1W4iLpYvqqQz+nZ1g9qT//zlswsM3amtoHFVTUTllFRU5HRQMIBC/eRNYLXEgMhRCZ6YyMthbscDFY8JZGNKTId5KjsepHQCmn19WlT2h6gAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "ui shell header submenu spec",
        "title": "ui shell header submenu spec",
        "src": "/static/567933379383cf030741e5c59d43144d/fb070/header-style-3.png",
        "srcSet": ["/static/567933379383cf030741e5c59d43144d/d6747/header-style-3.png 288w", "/static/567933379383cf030741e5c59d43144d/09548/header-style-3.png 576w", "/static/567933379383cf030741e5c59d43144d/fb070/header-style-3.png 1152w", "/static/567933379383cf030741e5c59d43144d/fb104/header-style-3.png 1728w", "/static/567933379383cf030741e5c59d43144d/8fefe/header-style-3.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">
  Structure and spacing measurements for header submenu | px | rem
    </Caption>
    <h3 {...{
      "id": "responsive-behavior"
    }}>{`Responsive behavior`}</h3>
    <p>{`In smaller broswer windows, `}<inlineCode parentName="p">{`menu items`}</inlineCode>{` in the header should collapse into the left side nav menu. Items that were once in the header should stack at the top of the side nav panel if other items were already present in the panel.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "60.44642857142857%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAAB1UlEQVQoz62Sy0uiURiHy0FUXKl4v6IiqMi4awpcRdAQzKZNMIsWrWYltOuCFRLUpsvkMNNlgpDatRokaDeblqLCfH9AKzeSE4pp6NdzPsVNJQYd+H2/c3nPc973nG8kmUyOvFdLJBLdjt/v/xqJRE4dDsd3l8uVEXK73Rmn05nx+XwZu93+w2w2H1gslt9Wq/UcZekrIu7M6/Ve4t/6ZAbzQC9YOGLziZDNZlPEIUJi/oxxEsgkwBn8sxCHTpPAF/xjH0jAHNDDQCCwYzAY9oxG4x7B+0Ks7bO26/F4TtDEoJLZ3+2EQqHFWCx2E4/Hr5i8JttrQDmAObLKkcEfoH/xWRFPxmrWVEL0VWQnfLRPliQp22g05HK5LFcqFblUKsnBYPDUZDL9otRD9JMNWTTVA6oGvk6tVjuSu+1BfJrN5h2PtE7pS8BWyHYJpdFY74oGA+v1+rEAtdvtlvBqtXofDoc3Aa4B3KDsFNpGyh3ig4EwFGCn01GA9zQBpOQU5a33gFtofCggmT0DRqPRTf69NR5iA2iKi9/GJ4a6wxeA/wVIrVav6PX6VZ1Ot6zRaNJarfaTiMffBmy1Wo+8tFQsFqVCofAPFfP5/C3jBRGPf3iN9QTai+6A0h/fRAAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "responsive behavior example",
        "title": "responsive behavior example",
        "src": "/static/df01f93f1051b5c4edb7e40d99f28ffa/fb070/header-style-4.png",
        "srcSet": ["/static/df01f93f1051b5c4edb7e40d99f28ffa/d6747/header-style-4.png 288w", "/static/df01f93f1051b5c4edb7e40d99f28ffa/09548/header-style-4.png 576w", "/static/df01f93f1051b5c4edb7e40d99f28ffa/fb070/header-style-4.png 1152w", "/static/df01f93f1051b5c4edb7e40d99f28ffa/fb104/header-style-4.png 1728w", "/static/df01f93f1051b5c4edb7e40d99f28ffa/8fefe/header-style-4.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">Responsive behavior for UI shell header</Caption>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      